import React, { useEffect, useState } from "react";
import GoogleMapsWrapper from "../maps/GoogleMapsWrapper";
import { usePlaceDetails } from "../../hooks/maps/usePlaceDetails";

const StateSelect = ({
  value,
  modifier,
  handleSelect,
  placeholder,
  required,
}) => {
  const [suggestionsOptions, setSuggestionsOptions] = useState([]);
  const { getPlaceDetails, findStates } = usePlaceDetails();

  const renderSuggestions = () => {
    if (
      Array.isArray(suggestionsOptions) &&
      suggestionsOptions.length > 0 &&
      value?.length > 0
    ) {
      return (
        <ul className="dropdown-menu d-flex flex-column position-absolute w-100 mx-auto">
          {suggestionsOptions.map((location) => (
            <li
              key={location}
              className="px-3 py-1 border-bottom hover-light"
              onClick={() => {
                setSuggestionsOptions([]);
                modifier(location);
              }}
            >
              {location}
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className="dropdown show">
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className="form-control"
        required={required}
        disabled
        onChange={(e) => {
          if(e.target.value) {
            findStates(e.target.value, setSuggestionsOptions);
          }
          modifier(e.target.value);
        }}
      />
      {renderSuggestions()}
    </div>
  );
};

export default (props) => (
  <GoogleMapsWrapper>
    <StateSelect {...props} />
  </GoogleMapsWrapper>
);
