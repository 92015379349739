
import React, { createContext, useReducer, useContext } from 'react';
import AnnouncementsService from '../services/AnnouncementsService';
import AnnouncementsReducer from '../reducers/AnnouncementsReducer';
import {
  ANNOUNCEMENTS_RECEIVED,
  SET_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  SET_PROPERTY_ANNOUNCEMENT,
} from "../types/announcements";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { handleFileUpload } from '../utils/files';

const initialState = {
  announcements: null,
  announcement: null,
};

export const AnnouncementsContext = createContext(initialState);

export const AnnouncementsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AnnouncementsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAnnouncements = () => {
    AnnouncementsService.getAnnouncements()
      .then((response) => {
        const { announcements } = response.data;
        dispatch({ type: ANNOUNCEMENTS_RECEIVED, payload: announcements });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleAnnouncement = (announcement_id) => {
    AnnouncementsService.getSingleAnnouncement(announcement_id)
      .then((response) => {
        const { announcement } = response.data;
        dispatch({ type: SET_ANNOUNCEMENT, payload: announcement });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setAnnouncement = (announcement) => {
    dispatch({ type: SET_ANNOUNCEMENT, payload: announcement });
  };

  const createAnnouncement = () => {
    dispatch({ type: CREATE_ANNOUNCEMENT });
  };

  const setPropertyAnnouncement = (key, value) => {
    dispatch({ type: SET_PROPERTY_ANNOUNCEMENT, payload: { key, value } });
  };

  const saveAnnouncement = async (announcement, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = AnnouncementsService.putAnnouncement;
      if(isNaN(parseInt(announcement.announcement_id))) {
        service = AnnouncementsService.postAnnouncement;
      }
      const file_id = await handleFileUpload(announcement);
      if(file_id && file_id !== null) {
        announcement.file_id = file_id;
      }
      service(announcement).then(() => {
        success("Anuncio guardado");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteAnnouncement = (announcement_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    AnnouncementsService.deleteAnnouncement(announcement_id).then(() => {
      success("Anuncio eliminado.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <AnnouncementsContext.Provider
    value={{
      ...state,
      setAnnouncement,
      getAnnouncements,
      saveAnnouncement,
      deleteAnnouncement,
      createAnnouncement,
      getSingleAnnouncement,
      setPropertyAnnouncement,

    }}
  >
    {children}
  </AnnouncementsContext.Provider>
);
};
