import FilesService from "../services/FilesService.js";

export const handleFileUpload = async (object) => {
  console.log(object);
  if (object.file && object.file !== null) {
    if (object.file.name && !object.file.createdAt) {
      const file_id = await uploadFile(object.file);
      console.log(file_id);
      return file_id;
    }
  }
  if (object.file_id && object.file_id !== null) {
    if (object.file_id.name && !object.file_id.createdAt) {
      const file_id = await uploadFile(object.file_id);
      return file_id;
    }
  }
};

export const uploadFile = async (file) =>
  new Promise((resolve, reject) => {
    console.log(file);
    const formData = FilesService.getFormData(file);
    FilesService.postS3File(formData)
      .then((res) => {
        const { file_id } = res.data;
        resolve(file_id);
      })
      .catch(reject);
  });
