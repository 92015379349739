import api from "./api";

const route = "/files";

const FilesService = {
  getFiles: () => api.get(route),
  getFormData: (file, data) => {
    const formData = new FormData();
    formData.append("file", file);
    if (data) formData.append("data", data);
    return formData;
  },
  getSingleFile: (file_id) => api.get(`${route}/${file_id}`),
  postFile: (formData) =>
    api.post(route, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  postS3File: (formData) =>
    api.post(`${route}/s3`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  putFile: (file) => api.put(route, { ...file }),
  deleteFile: (file_id) => api.delete(`${route}/${file_id}`),
};

export default FilesService;