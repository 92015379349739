import React, { useContext, useState } from "react";
import { getValue } from "../../utils";
import { LocationsContext } from "../../context/LocationsContext";
import GoogleMapsAutocomplete from "../maps/GoogleMapsAutocomplete";
import NeighborhoodSelect from "./NeighborhoodSelect";
import CitySelect from "./CitySelect";
import StateSelect from "./StateSelect";
import CountrySelect from "./CountrySelect";

const AddressForm = ({
  title,
  object,
  modifier,
  saveAction,
  hideButtons,
  handleCancel,
}) => {
  const { location } = useContext(LocationsContext);
  const [selected, setSelected] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (typeof saveAction === "function") {
      return saveAction(location);
    }
  };

  const handleSelectAutocomplete = (addressData) => {
    modifier(addressData);
  };

  const renderButtons = () => {
    if (!hideButtons) {
      return (
        <div className="row mt-3">
          <div className="col-12 px-0">
            <button type="submit" className="btn btn-primary w-100">
              Guardar
            </button>
          </div>
          {handleCancel && (
            <div className="col text-end">
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-link text-muted px-0"
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  const locationModifier = (key, value) => {
    const newValue = {
      ...object,
      [key]: value,
    };

    modifier(newValue);
  };

  return (
    <form className="row mb-5" onSubmit={handleSubmit}>
      {title && <h2>{title}</h2>}
      <div className="col-md-6 mb-2 ps-0">
        <label htmlFor="street" className="form-label">
          Calle
        </label>
        <GoogleMapsAutocomplete
          required
          value={getValue(object, "street")}
          modifier={(value) => {
            locationModifier("street", value);
          }}
          handleSelect={(selectedAddress) => {
            console.log(selectedAddress);
            modifier(selectedAddress);
            if(selectedAddress.google_place_id) {
              setSelected(true);
            } else {
              setSelected(false);
            }
          }}
        />
      </div>
      <div className="col-md-3 col-6 mb-2 ps-0">
        <label htmlFor="number" className="form-label">
          Número
        </label>
        <input
          type="text"
          id="number"
          name="number"
          className="form-control"
          value={getValue(object, "number")}
          onChange={(event) => locationModifier("number", event.target.value)}
          required
        />
      </div>

      <div className="col-md-3 col-6 mb-2 pe-0">
        <label htmlFor="interiorNumber" className="form-label">
          Interior
        </label>
        <input
          type="text"
          id="interiorNumber"
          name="interiorNumber"
          className="form-control"
          value={getValue(object, "interior_number")}
          onChange={(event) =>
            locationModifier("interior_number", event.target.value)
          }
        />
      </div>

      <div className="col-12 px-0 mb-2">
        <label htmlFor="neighborhood" className="form-label">
          Colonia
        </label>
        <NeighborhoodSelect
          required
          disabled={selected || object?.google_place_id}
          value={getValue(object, "neighborhood")}
          modifier={(value) => locationModifier("neighborhood", value)}
          handleSelect={(selectedAddress) => {
            modifier({
              ...selectedAddress,
              street: object.street
            });
            setSelected(true);
          }}
        />
      </div>
      <div className="col-12 px-0 mb-2">
        <label htmlFor="city" className="form-label">
          Ciudad
        </label>
        <CitySelect
          required
          disabled
          value={getValue(object, "city")}
          modifier={(value) => locationModifier("city", value)}
        />
      </div>
      <div className="col-md-4 col-6 mb-2 ps-0">
        <label htmlFor="zip_code" className="form-label">
          Código Postal
        </label>
        <input
          type="text"
          id="zip_code"
          name="zip_code"
          className="form-control"
          value={getValue(object, "zip_code")}
          onChange={(event) => locationModifier("zip_code", event.target.value)}
          required
        />
      </div>
      <div className="col-md-4 col-6 mb-2 pe-0">
        <label htmlFor="state" className="form-label">
          Estado
        </label>
        <StateSelect
          required
          value={getValue(object, "state")}
          modifier={(value) => locationModifier("state", value)}
        />
      </div>
      <div className="col-md-4 col-12 mb-2">
        <label htmlFor="country" className="form-label">
          País
        </label>
        <CountrySelect
          required
          value={getValue(object, "country")}
          modifier={(value) => locationModifier("country", value)}
        />
      </div>
      <div className="col-12">{renderButtons()}</div>
    </form>
  );
};

export default AddressForm;
