
export const usePlaceDetails = () => {
  const addressComponentsKeys = {
    country: 'country',
    state: 'administrative_area_level_1',
    city: 'locality',
    neighborhood: 'sublocality',
    street: 'route',
    zip_code: 'postal_code',
  }

  const findPlacesSuggestions = (query, callback) => {
    const service = new window.google.maps.places.AutocompleteService;
  
    const params = {
      input: query,
      types: ['route', 'street_address', 'street_number']
    }

    service.getPlacePredictions(params, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if(typeof callback === 'function') callback(result);
      }
    });
  }

  const findSuggestionsByPlaceType = async (query, placeType) => {
    const primaryTypes = {
      country: ['country'],
      state: ['administrative_area_level_1'],
      city: ['locality'],
      neighborhood: ['sublocality']
    }

    const fetchData = window.google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions;
    const data = await fetchData({
      input: query,
      includedPrimaryTypes: primaryTypes[placeType],
      language: 'es'
    });



    return data.suggestions;
  }

  const findNeighborhoods = async (query, callback) => {
    const suggestions = await findSuggestionsByPlaceType(query, 'neighborhood');

    const neighborhoods = suggestions.map(suggestion => {
      return {
        label: suggestion.placePrediction.text.text,
        placeId: suggestion.placePrediction.placeId
      }
    });

    callback(neighborhoods);
  }

  const findCities = async (query, callback) => {
    const suggestions = await findSuggestionsByPlaceType(query, 'city');

    const cities = suggestions.map(suggestion => {
      return {
        label: suggestion.placePrediction.text.text,
        placeId: suggestion.placePrediction.placeId
      }
    });

    const filteredData = [...new Set(cities)];
    callback(filteredData);
  }

  const findStates = async (query, callback) => {
    const suggestions = await findSuggestionsByPlaceType(query, 'city');

    const states = suggestions.map(suggestion => {
      return suggestion.placePrediction.text.text;
    });

    const filteredData = [...new Set(states)];
    callback(filteredData);
  }

  const findCountries = async (query, callback) => {
    const suggestions = await findSuggestionsByPlaceType(query, 'country');

    const countries = suggestions.map(suggestion => {
      return suggestion.placePrediction.mainText.text;
    });

    const filteredData = [...new Set(countries)];
    callback(filteredData);
  }

  const getPlaceDetails = (placeId, callback) => {
    if (!placeId || !window.google) return null;

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));

    const request = {
      placeId,
      fields: ['name', 'formatted_address', 'geometry', 'type', 'address_components'],
    };

    return new Promise((resolve, reject) => {
      service.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressData = { 
            google_place_id: placeId
          };
          if(Array.isArray(place.address_components) && place.address_components.length > 0) {
            Object.keys(addressComponentsKeys).map(key => {
              const keyValue = addressComponentsKeys[key];
              addressData[key] = '';
  
              place.address_components.map(component => {
                if(component.types.includes(keyValue)){
                  addressData[key] = component.long_name;
                }
              })
            });
          }

          if(typeof callback === 'function') callback(addressData);
          resolve(addressData);
        } else {
          console.log('error en usePlaceDetails');
          console.log(status);
          reject(status);
        }
      });
    })
  }

  return {
    getPlaceDetails,
    findPlacesSuggestions,
    findCountries,
    findStates,
    findCities,
    findNeighborhoods
  }
}