import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_KEY } from "../../utils";

const libraries = ['places']
const GoogleMapsWrapper = ({ children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries,
  });

  if (!isLoaded) return <div className="spinner-border spinner-border-sm d-block"></div>;

  return <div className="px-0">{children}</div>;
};

export default GoogleMapsWrapper;