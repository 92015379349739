import { getArgs } from "../utils";
import api from "./api";

const route = "/custom_fields";

const CustomFieldsService = {
  getCustomFields: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getSingleCustomField: (custom_field_id) =>
    api.get(`${route}/${custom_field_id}`),
  postCustomField: (custom_field) => api.post(route, { ...custom_field }),
  putCustomField: (custom_field) => api.put(route, { ...custom_field }),
  deleteCustomField: (CustomField) => api.delete(`${route}/${CustomField}`),
};

export default CustomFieldsService;