
import {
  SET_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  ANNOUNCEMENTS_RECEIVED,
  SET_PROPERTY_ANNOUNCEMENT,
} from "../types/announcements";

const schema = {

}

const announcementsReducer = (state, { type, payload }) => {
  switch (type) {
    case ANNOUNCEMENTS_RECEIVED:
      return { ...state, announcements: payload };
    case SET_ANNOUNCEMENT:
      return { ...state, announcement: payload };
    case CREATE_ANNOUNCEMENT:
      return { ...state, announcement: schema };
    case SET_PROPERTY_ANNOUNCEMENT: {
      const { key, value } = payload;
      const announcement = { ...state.announcement };
      announcement[key] = value;
      return { ...state, announcement };
    }
    default:
      return { ...state};
  }
};

export default announcementsReducer;
