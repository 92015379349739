
import api from './api';

const route = '/announcements';

const AnnouncementsService = {
  getAnnouncements: () => api.get(`${route}/admin/all`),
  getSingleAnnouncement: (announcement_id) => api.get(`${route}/${announcement_id}`),
  postAnnouncement: (announcement) => api.post(route, { ...announcement}),
  putAnnouncement: (announcement) => api.put(route, { ...announcement}),
  deleteAnnouncement: (Announcement) => api.delete(`${route}/${Announcement}`),
};

export default AnnouncementsService;