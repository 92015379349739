import React, { useState } from "react";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import { usePlaceDetails } from "../../hooks/maps/usePlaceDetails";

const MapsAutocomplete = ({ value, modifier, handleSelect, placeholder, required }) => {
  const [suggestionsOptions, setSuggestionsOptions] = useState([]);
  const { getPlaceDetails, findPlacesSuggestions } = usePlaceDetails();

  const renderSuggestions = () => {
    if (Array.isArray(suggestionsOptions) && suggestionsOptions.length > 0) {
      return (
        <ul className="dropdown-menu d-flex flex-column position-absolute w-100 mx-auto">
          <li
            className="px-3 py-1 border-bottom hover-light"
            onClick={() => {
              setSuggestionsOptions([]);
              handleSelect({street: value})
            }}
          >
            "{value}"
          </li>
          {suggestionsOptions.map((location) => (
            <li
              key={location.place_id}
              className="px-3 py-1 border-bottom hover-light"
              onClick={() => {
                setSuggestionsOptions([]);
                getPlaceDetails(location.place_id, handleSelect);
              }}
            >
              {location.description}
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className="dropdown show">
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        className="form-control"
        required={required}
        onChange={(e) => {
          modifier(e.target.value);
          findPlacesSuggestions(e.target.value, setSuggestionsOptions);
        }}
      />
      {renderSuggestions()}
    </div>
  );
};

export default (props) => (
  <GoogleMapsWrapper>
    <MapsAutocomplete {...props} />
  </GoogleMapsWrapper>
);