import { useEffect, useContext } from "react";
import DynamicForm from "../global/DynamicForm";
import { AnnouncementsContext } from "../../context/AnnouncementsContext";

const AnnouncementForm = ({ handleCancel, announcement_id }) => {
  const {
    announcement,
    saveAnnouncement,
    createAnnouncement,
    getSingleAnnouncement,
    setPropertyAnnouncement,
  } = useContext(AnnouncementsContext);

  useEffect(() => {
    if (isNaN(parseInt(announcement_id))) {
      createAnnouncement();
    } else {
      getSingleAnnouncement(announcement_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DynamicForm
      labels={{
        title: "Título",
        content: "Contenido",
        is_public: "Público",
        is_archived: "Archivado",
        end_date: "Fecha de fin",
        file_id: "Imagen (opcional)",
        start_date: "Fecha de inicio",
      }}
      classNames={[
        {
          key: "is_public",
          className: "col-6",
        },
        {
          key: "is_archived",
          className: "col-6",
        },
        {
          key: "start_date",
          className: "col-6",
        },
        {
          key: "end_date",
          className: "col-6",
        },
      ]}
      object={announcement}
      objectType="announcement"
      handleCancel={handleCancel}
      saveAction={saveAnnouncement}
      modifier={setPropertyAnnouncement}
    />
  );
};

export default AnnouncementForm;
